import { FormattedMessage } from 'react-intl';

import { AddLensesStep } from './order-create-steps/add-lenses-step';
import { EcpStep } from './order-create-steps/ecp-step';
import { ConfirmStep } from './order-create-steps/confirm-step';
import React from 'react';

export const orderCreateSteps = (customIndex?: number) => ([
  {
    StepComponent: AddLensesStep,
    id: 'products',
    nextButtonAriaLabel: <FormattedMessage id="order.create.addLenses.button.label" />,
    disableNextButton: true,
  },
  {
    StepComponent: EcpStep,
    id: 'store',
    disableNextButton: true,
  },
  {
    StepComponent: ConfirmStep,
    id: 'confirm',
    activeStepIndex: customIndex,
    nextButtonLabel: <FormattedMessage id="order.create.confirm.button.label" />,
    disableNextButton: true,
  },
]);

import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  root: {
    marginBottom: 32,
    [breakpoints.up('md')]: {
      marginBottom: 64,
    },
  },
  rootResponsiveSizeXs: {
    width: 320,
    [breakpoints.up('md')]: {
      width: 525,
    },
  },
  rootResponsiveSizeFull: {
    width: '100%',
    [breakpoints.up('sm')]: {
      width: 542,
    },
  },
  rootCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paperRoot: {
    position: 'relative',
    padding: '32px',
    boxShadow: `0 4px 8px ${palette.grey['300']}`,
    borderRadius: 12,
    [breakpoints.up('md')]: {
      padding: '64px 80px',
    },
  },
  title: {
    position: 'relative',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    color: palette.text.secondary,
    margin: '0 0 8px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
      marginBottom: 16,
    },
  },
  titleWithoutDescription: {
    marginBottom: 24,
    [breakpoints.up('md')]: {
      marginBottom: 32,
    },
  },
  description: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.secondary,
    margin: '0 0 24px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
      marginBottom: 32,
    },
  },
  footer: {
    padding: '16px 0',
    [breakpoints.up('md')]: {
      paddingTop: '28px',
      paddingBottom: '28px',
    },
  },
  footerDescription: {
    textAlign: 'center',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.secondary,
    margin: 0,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  backButton: {
    position: 'absolute',
    top: 0,
    right: '100%',
    [breakpoints.up('md')]: {
      marginRight: 20,
    },
  },
  rootResponsiveMarginsSm: {
    marginTop: 32,
    marginBottom: 32,
    [breakpoints.up('md')]: {
      marginTop: 64,
      marginBottom: 64,
    },
    [breakpoints.up('lg')]: {
      marginTop: 80,
      marginBottom: 80,
    },
  },
  rootResponsiveMarginsXs: {
    marginTop: 20,
    marginBottom: 32,
    [breakpoints.up('sm')]: {
      marginTop: 32,
      marginBottom: 80,
    },
  },
}));

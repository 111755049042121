import React from 'react';
import { FormattedMessage } from 'react-intl';
import { cx } from '@emotion/css';
import { useReactiveVar } from '@apollo/client';

import { Modal, ResponsiveWidth } from '../../../../common/modal';
import { Logo } from '../../../../common/logo';
import { useStyles } from './barcode-modal.styles';
import { Barcode } from '../../../../common/barcode';
import { IBarcodeModalProps } from '../barcode.models';
import { Preloader } from '../../../../common/preloader';
import {
  setIsFreepacks,
  setIsLocatorIconsCampaign,
} from '../../../../../graphql/configuration/configuration.cache';

export const BarcodeModal = (props: IBarcodeModalProps): JSX.Element => {
  const classes = useStyles();
  const {
    myAcuvueId, myAcuvueCode = '', isLoading, isError, modalProps,
  } = props;
  const responsiveWidth = ResponsiveWidth.lg;

  const isCampaignIcon = useReactiveVar(setIsLocatorIconsCampaign);
  const isFreepacksEnabled = useReactiveVar(setIsFreepacks);
  const barcodeDescription = isFreepacksEnabled
    ? 'barcode.description.withFreepacks'
    : 'barcode.description';

  const renderBarcode = () => {
    if (isLoading) {
      return <Preloader />;
    }
    if (isError) {
      return null;
    }
    return (
      <>
        <p className={classes.hint}>
          <FormattedMessage id="barcode.userId" />
        </p>
        <p className={classes.id}>
          { myAcuvueId }
        </p>
        <p className={classes.description}>
          <FormattedMessage
            id={barcodeDescription}
            values={{
              linebreak: <br className={classes.descriptionLinebreak} />,
            }}
          />
        </p>
        <Barcode value={`${myAcuvueId}${myAcuvueCode}`} className={classes.barcode} />
        {isCampaignIcon && (
          <span
            data-testid="campaign-wrapper"
            aria-hidden="true"
            className={classes.campaign}
          />
        )}
      </>
    );
  };

  return (
    <Modal
      {...modalProps}
      responsiveWidth={responsiveWidth}
      propsContentStyles={classes.propsContentStyles}
      rootStyles={isCampaignIcon ? classes.rootAbsolute : undefined}
      PaperProps={{
        className: cx(classes.dialogPaper,
          { [classes.dialogPaperCampaign]: isCampaignIcon },
        ),
      }}
      header={(
        <Logo size="lg" />
      )}
      content={(
        <div>
          {renderBarcode()}
        </div>
      )}
    />
  );
};

import { FormattedMessage, useIntl } from 'react-intl';

import { ConfirmationModal } from '../../../../../../../../common/confirmation-modal';
import {
  Icon,
  IconTypes,
  ResponsiveWidth,
} from '../../../../../../../../common';
import { IDeleteLensesModalProps } from './delete-lenses-modal.models';
import { useStyles } from './delete-lenses-modal.styles';
import { getBrandFullName } from '../../../../../../../../../utils/lenses/get-brand-full-name';

export const DeleteLensesModal = ({
  onDeleteConfirm, onClose, brand, ...props
}: IDeleteLensesModalProps): JSX.Element => {
  const handleConfirm = () => {
    onDeleteConfirm(0);
    onClose!();
  };

  const handleReject = () => {
    onClose!();
  };

  const classes = useStyles();
  const intl = useIntl();

  return (
    <ConfirmationModal
      {...props}
      topIcon={(
        <span
          data-testid="icon-wrapper"
          aria-hidden='true'
          className={classes.iconWrapper}
        >
          <Icon type={IconTypes.cartWithCross} />
        </span>
      )}
      title={<FormattedMessage id="order.deleteLenses.title" />}
      content={(
        <FormattedMessage id="order.deleteLenses.description" />
      )}
      confirmButtonLabel={<FormattedMessage id="order.deleteLenses.button" />}
      onConfirm={handleConfirm}
      onReject={handleReject}
      rejectOnClose
      confirmButtonAriaLabel={intl.formatMessage(
        { id: 'order.deleteLenses.button.ariaLabel' },
        { brand: intl.formatMessage(
          { id: getBrandFullName(brand) },
          { sup: (chunks) => `${chunks}` },
        ) },
      )}
      contentStyles={classes.content}
      titleStyles={classes.title}
      responsiveWidth={ResponsiveWidth.mdWide}
    />
  );
};

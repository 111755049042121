import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  backdropRoot: {
    backgroundColor: palette.grey['600'],
  },
  dialogPaper: {
    zIndex: 1,
    maxWidth: '100%',
    borderRadius: 12,
    padding: '32px 0',
    margin: 0,
    boxShadow: `0 4px 8px ${palette.grey['300']}`,
    [breakpoints.up('md')]: {
      paddingTop: 64,
      paddingBottom: 64,
    },
  },
  dialogPaperWithoutTitle: {
    paddingTop: 48,
    [breakpoints.up('md')]: {
      paddingTop: 64,
    },
  },
  dialogPaperResponsiveWidthDefault: {
    width: '90vw',
    maxWidth: 310,
    [breakpoints.up('md')]: {
      width: 542,
      maxWidth: 'unset',
    },
  },
  dialogPaperResponsiveWidthXs: {
    width: '90vw',
    maxWidth: 310,
    [breakpoints.up('md')]: {
      width: 393,
      maxWidth: 'unset',
    },
  },
  dialogPaperResponsiveWidthSm: {
    width: '90vw',
    maxWidth: 310,
    [breakpoints.up('md')]: {
      width: 422,
      maxWidth: 'unset',
    },
  },
  dialogPaperResponsiveWidthMd: {
    width: '90vw',
    maxWidth: 310,
    [breakpoints.up('md')]: {
      width: 440,
      maxWidth: 'unset',
    },
  },
  dialogPaperResponsiveWidthMdwide: {
    width: '90vw',
    maxWidth: 310,
    [breakpoints.up('md')]: {
      width: 488,
      maxWidth: 'unset',
    },
  },
  dialogPaperResponsiveWidthMdwider: {
    width: '90vw',
    maxWidth: 310,
    [breakpoints.up('md')]: {
      width: 520,
      maxWidth: 'unset',
    },
  },
  dialogPaperResponsiveWidthXsfull: {
    width: '100%',
    maxWidth: 'unset',
    [breakpoints.up('sm')]: {
      width: 542,
    },
  },
  dialogPaperResponsiveWidthMdfixed: {
    width: 300,
    [breakpoints.up('md')]: {
      width: 440,
      maxWidth: 'unset',
    },
  },
  dialogPaperResponsiveWidthLg: {
    width: '90vw',
    [breakpoints.up('md')]: {
      width: 703,
    },
  },
  dialogPaperResponsiveWidthLgwide: {
    width: '90vw',
    [breakpoints.up('lg')]: {
      width: 776,
    },
  },
  dialogPaperResponsiveWidthXl: {
    width: '90vw',
    [breakpoints.up('sm')]: {
      width: 388,
    },
    [breakpoints.up('lg')]: {
      width: 946,
    },
  },
  dialogPaperResponsiveWidthXlwide: {
    width: '90vw',
    [breakpoints.up('sm')]: {
      width: 388,
    },
    [breakpoints.up('md')]: {
      width: 703,
    },
    [breakpoints.up('lg')]: {
      width: 946,
    },
  },
  dialogContentRoot: {
    padding: 0,
  },
  closeIconButton: {
    position: 'absolute',
    top: 12,
    right: 12,
    zIndex: 10,
    [breakpoints.up('md')]: {
      top: 16,
      right: 16,
    },
  },
  header: {
    marginBottom: 16,
    padding: '0 24px',
    [breakpoints.up('md')]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
  content: {
    padding: '0 24px',
    [breakpoints.up('md')]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
  title: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.lg,
    color: palette.text.secondary,
    margin: 0,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
    },
  },
  footer: {
    padding: '16px 24px 0',
    [breakpoints.up('md')]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
  footerWithLine: {
    borderTop: '1px solid',
    borderColor: palette.grey['400'],
    padding: '24px 24px 0',
    [breakpoints.up('md')]: {
      paddingTop: 32,
    },
  },
}));

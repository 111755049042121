import { EcpLocator } from '../ecp-locator';
import { Wiki } from '../wiki';
import { SignUpSlide } from '../sign-up-slide';
import { WhyMyAcuvue } from '../why-my-acuvue';
import { VisionProfileUnregistered } from '../vision-profile-unregistered';
import { PrimaryBannerUnregistered } from '../primary-banner-unregistered';

export const LandingPageNotLoggedIn = (): JSX.Element => (
  <>
    <SignUpSlide />
    <WhyMyAcuvue />
    <VisionProfileUnregistered />
    <EcpLocator />
    <Wiki />
    <PrimaryBannerUnregistered />
  </>
);

import React, { useCallback, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import { useGoBack } from '../../../../../hooks/use-go-back';
import { Preloader } from '../../../../common/preloader';
import { OrderLayout } from './order-layout';
import { IOrderCreateProps } from './order-create-page.models';
import { setIsRepeatOrder } from '../../../../../graphql/order/order.cache';
import { useEcpStoresFromCache } from '../../../../../graphql/ecp-locator/actions/ecp-stores';
import { getLensesWithoutAohm } from '../../../../../utils/lenses/get-lenses-without-aohm';
import { OrderRepeatPage } from './order-repeat-page';
import { cleanupState } from './utils/cleanup-state';

export const OrderCreatePage = ({
  isError, isLoading, onDone, lensesData, brandsData,
}: IOrderCreateProps): JSX.Element => {
  const { goBack } = useGoBack();
  const handleBack = useCallback(() => goBack(), [goBack]);

  const { setEcpStoresToCache } = useEcpStoresFromCache();
  const isRepeatOrder = useReactiveVar(setIsRepeatOrder);

  const renderOrderCreatePage = () => {
    if (isLoading) {
      return <Preloader />;
    }

    // Hide C2C feature from aohm-only fitted users
    const lensesWithoutAohm = getLensesWithoutAohm(lensesData!);

    if (isError || lensesWithoutAohm && !lensesWithoutAohm?.length) {
      handleBack();
    }

    return (
      <>{isRepeatOrder ? (
        <OrderRepeatPage
          lenses={lensesWithoutAohm!}
          onDone={onDone}
          onBack={handleBack}
          brandsData={brandsData}
        />
      ) : (
        <OrderLayout
          lenses={lensesWithoutAohm!}
          onDone={onDone}
          onBack={handleBack}
          brandsData={brandsData}
        />
      )}</>
    );
  };

  useEffect(() => {
    setEcpStoresToCache([]);

    return () => {
      cleanupState();
      setIsRepeatOrder(false);
    };
  }, []);

  return renderOrderCreatePage();
};

import { UserActivitiesList } from '../../../../../common/user-activities-list';
import { OrderCreateButton } from '../../../order/order-create-button';
import { IMyOrdersListProps } from './my-orders-list.models';
import { useStyles } from './my-orders-list.styles';

export const MyOrdersList = <T extends { id: string }>({
  isFitted, orders,
}: IMyOrdersListProps<T>): JSX.Element => {
  const classes = useStyles();

  return (
    <UserActivitiesList
      activities={orders}
      footer={!!orders.length && (
        <div className={classes.footer}>
          <OrderCreateButton
            isFitted={isFitted}
          />
        </div>
      )}
      isStickyFooter
    />
  );
};

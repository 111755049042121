import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ITitleSize, UserActivitiesList } from '../../../../../common/user-activities-list';
import { OrderCreateButton } from '../../../order/order-create-button';
import { OrdersPlaceholder } from '../../../order/placeholder';
import { IMyOrdersListProps } from './my-orders-list.models';
import { useStyles } from './my-orders-list.styles';
import { Button } from '../../../../../common';
import { InternalLinks } from '../../../../../../constants';
import { useGoBack } from '../../../../../../hooks/use-go-back';

export const MyOrdersList = <T extends { id: string }>({
  isFitted, orders, isActionShow,
}: IMyOrdersListProps<T>): JSX.Element => {
  const classes = useStyles();
  const { hasPreviousLocationState } = useGoBack();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const intl = useIntl();

  return (
    <UserActivitiesList
      title={{
        size: ITitleSize.lg,
        label: (
          <FormattedMessage
            id="myOrdersPreview.title"
          />
        ),
      }}
      activities={orders}
      placeholder={(
        <OrdersPlaceholder
          action={(
            <OrderCreateButton
              isFitted={isFitted}
            />
          )}
        />
      )}
      footer={!!orders.length && (
        <div className={classes.footer}>
          <OrderCreateButton
            isFitted={isFitted}
          />
        </div>
      )}
      action={(orders.length && isActionShow) && (
        <Button
          to={InternalLinks.myOrdersPage}
          data-testid="my-orders-page-link"
          state={hasPreviousLocationState}
          aria-label={intl.formatMessage(({ id: 'myOrdersPreview.all.label' }))}
          className={classes.actionButton}
        >
          <FormattedMessage
            id={isDesktop ? 'myOrdersPreview.all.label' : 'myOrdersPreview.all.mobile.label'}
          />
        </Button>
      )}
    />
  );
};

import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useModal } from 'mui-modal-provider';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useReactiveVar } from '@apollo/client';

import { useStyles } from './my-bonuses.styles';
import { ContentWrapper } from '../content-wrapper';
import { IMyBonusesProps } from './my-bonuses.models';
import { Preloader } from '../../../common/preloader';
import { useGoBack } from '../../../../hooks/use-go-back';
import { Button, Icon, IconSizes, IconTypes } from '../../../common';
import { BackButton } from '../../../common/back-button';
import { MyPointsTutorial } from './my-bonuses-tutorial';
import { GET_IS_BONUSES_TUTORIAL_SHOWN } from '../../../../graphql/user/queries';
import { InternalLinks, SECONDARY_BACKGROUND } from '../../../../constants';
import { TechIssues } from '../../../common/tech-issues';
import { MyBonusesSection } from './my-bonuses-section';
import { FreepacksPlaceholder } from '../user-activity-items/freepacks';
import { PromotionsPlaceholder } from '../user-activity-items/promotions';
import { setIsFreepacks } from '../../../../graphql/configuration/configuration.cache';

export const MyBonuses = ({ isLoading, isError, bonusCards, balance }: IMyBonusesProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const { hasPreviousLocationState } = useGoBack();
  const { data: { user: { isBonusesTutorialShown } } } = useQuery(GET_IS_BONUSES_TUTORIAL_SHOWN);
  const { showModal } = useModal();
  const { freepacks, discounts, promotions } = bonusCards;
  const isFreepacksEnabled = useReactiveVar(setIsFreepacks);

  useEffect(() => {
    if (!isBonusesTutorialShown) {
      showModal(MyPointsTutorial, {});
    }
  }, [isBonusesTutorialShown, showModal]);

  const renderContent = () => {
    if (isLoading) {
      return <Preloader />;
    }

    if (isError) {
      return <TechIssues />;
    }

    return (
      <ContentWrapper>
        <Helmet>
          <title>
            {intl.formatMessage({ id: 'myPointsPage.title' })}
          </title>
          <meta name="description" content={
            intl.formatMessage({ id: 'myPointsPage.description' })} />
          <style type="text/css">
            {`
              body {
                background-color: ${SECONDARY_BACKGROUND};
              }
            `}
          </style>
        </Helmet>
        <div className={classes.root}>
          <BackButton />
          <div className={classes.content}>
            <h1
              className={classes.balance}
              data-testid="points-balance"
            >
              <FormattedMessage
                id="pointsHowTo.balance"
                values={{ balance }}
              />
            </h1>
            <Link
              className={classes.howToButton}
              data-testid="points-how-to-link"
              to={InternalLinks.pointsHowToPage}
              state={hasPreviousLocationState}
            >
              <Button
                variant="text"
              >
                <FormattedMessage
                  id="myPointsDetailed.howToButton.label"
                />
              </Button>
              <Icon
                type={IconTypes.helpMark}
                size={IconSizes.xs}
              />
            </Link>
            <MyBonusesSection
              data-testid="section-promotions"
              title={<FormattedMessage id="myPointsDetailed.promotions.title" />}
              ariaLabel={intl.formatMessage({ id: 'myPointsDetailed.promotions.title' })}
              items={promotions}
              placeholder={<PromotionsPlaceholder />}
            />
            <MyBonusesSection
              data-testid="section-discounts"
              title={<FormattedMessage id="myPointsDetailed.discounts.title" />}
              ariaLabel={intl.formatMessage({ id: 'myPointsDetailed.discounts.title' })}
              items={discounts}
            />
            {isFreepacksEnabled && (
              <MyBonusesSection
                data-testid="section-freepacks"
                title={<FormattedMessage id="myPointsDetailed.freePacks.title" />}
                ariaLabel={intl.formatMessage({ id: 'myPointsDetailed.freePacks.title' })}
                items={freepacks}
                placeholder={<FreepacksPlaceholder />}
              />
            )}
          </div>
        </div>
      </ContentWrapper>
    );
  };

  return renderContent();
};

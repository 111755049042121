import { makeVar } from '@apollo/client';

import { TEcpLocatorResponse } from '../../rest/ecp-locator';
import { IOrderSelectedBonus, IProduct } from './models/get-orders.models';
import { getOrderModalFromStorage } from '../../utils/order/get-order-modal-from-storage';
import {
  IOrderStore,
} from '../../components/business/app/order/order-create-page/order-create-page.models';
import {
  IPackageParams,
} from '../../components/business/app/order/order-create-page/order-create-steps/add-lenses-step/add-lenses-step-form';

export const setDeliveryStore = makeVar<IOrderStore | null>(null);
export const setOrderNumber = makeVar<string>('');
export const setOrderBonusId = makeVar<string>('');
export const setOrderBonus = makeVar<IOrderSelectedBonus | null>(null);

export const setIsRepeatOrder = makeVar<boolean>(false);
export const setRepeatOrderProducts = makeVar<IProduct[] | null>(null);

export const orderModal = makeVar(getOrderModalFromStorage());
export const isOrderModalShown = makeVar(!!orderModal());

export const setOrderStores = makeVar<TEcpLocatorResponse>([]);
export const setOrderStoreCode = makeVar<string>('');

export const setConsumerProducts = makeVar<IPackageParams[] | null>(null);

import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from 'mui-modal-provider';
import { useQuery } from '@apollo/client';

import { Button } from '../../../common/button';
import { Icon, IconTypes } from '../../../common/icon';
import { BarcodeModal } from './barcode-modal';
import { IBarcodeAction } from './barcode.models';
import { GET_BARCODE } from '../../../../graphql/profile/queries';
import { IBarcodeData } from '../../../../graphql/profile/models/get-barcode.models';

export const BarcodeAction = ({ isLabelHidden }: IBarcodeAction): JSX.Element => {
  useQuery<IBarcodeData>(GET_BARCODE);
  const { showModal } = useModal();

  const handleOpenModalButtonClick = useCallback(() => {
    showModal(BarcodeModal, {});
  }, [showModal]);

  return (
    <Button
      color="info"
      startIcon={<Icon type={IconTypes.barCode} />}
      hideText={isLabelHidden}
      onClick={handleOpenModalButtonClick}
      labelClassName="normal"
      underline
    >
      <FormattedMessage id="header.myBarcode" />
    </Button>
  );
};

import { FormattedMessage } from 'react-intl';

import { Button } from '../../../../common';
import { InternalLinks } from '../../../../../constants';
import { useGoBack } from '../../../../../hooks/use-go-back';
import { ICreateButtonProps } from './order-create-button.models';
import { useStyles } from './order-create-button.styles';

export const OrderCreateButton = ({ handleClick, isFitted }: ICreateButtonProps): JSX.Element => {
  const { hasPreviousLocationState } = useGoBack();
  const classes = useStyles();

  return (
    <>
      {
        isFitted ? (
          <Button
            to={InternalLinks.orderCreate}
            state={hasPreviousLocationState}
            data-testid="order-create-link"
            variant="outlined"
            className={classes.root}
          >
            <FormattedMessage id="order.create.button.label"/>
          </Button>
        ) : (
          <Button
            onClick={handleClick}
            data-testid="order-create-button"
            variant="outlined"
          >
            <FormattedMessage id="order.create.button.label"/>
          </Button>
        )
      }
    </>
  );
};

import { FormattedMessage } from 'react-intl';

import { NotificationType } from '../../../../../../../../graphql/reminders/models/reminder.models';
import { IOptions } from '../../../../../../../common/select';

export const changeNotificationItems: IOptions[] = [{
  value: NotificationType.threeDays,
  label: (
    <FormattedMessage
      id="reminders.modal.form.notificationToChange.options.threeDays"
    />
  ),
}, {
  value: NotificationType.twoDays,
  label: (
    <FormattedMessage
      id="reminders.modal.form.notificationToChange.options.twoDays"
    />
  ),
}, {
  value: NotificationType.oneDay,
  label: (
    <FormattedMessage
      id="reminders.modal.form.notificationToChange.options.oneDay"
    />
  ),
}, {
  value: NotificationType.none,
  label: (
    <FormattedMessage
      id="reminders.modal.form.notificationToChange.options.none"
    />
  ),
}];

import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

import { useStyles } from './order-layout.styles';
import { ContentWrapper } from '../../../content-wrapper';
import { Box, ResponsiveMargins, ResponsiveSize } from '../../../../../common/box';
import { Stepper } from '../../../../../common/stepper';
import { orderCreateSteps } from '../order-create.steps';
import { SECONDARY_BACKGROUND } from '../../../../../../constants';
import { CONFIRM_ORDER_INDEX } from '../../../../../../constants';
import { IOrderLayoutProps } from './order-layout.models';

export const OrderLayout  = ({
  onDone, brandsData, isRepeatOrder, lenses, onBack, store, products,
}: IOrderLayoutProps): JSX.Element => {
  const  classes = useStyles();
  return (
    <ContentWrapper withoutPaddings>
      <Helmet>
        <style type="text/css">
          {`
              body {
                background-color: ${SECONDARY_BACKGROUND};
              }
            `}
        </style>
      </Helmet>
      <h1 data-testid="order-create-title" className={classes.title}>
        <FormattedMessage
          id="order.create.title"
        />
      </h1>
      <Box
        center
        responsiveMargins={ResponsiveMargins.xs}
        responsiveSize={ResponsiveSize.full}
      >
        <Stepper
          disableStepIndicators
          externalState={{ lenses, brandsData, store, products, isRepeatOrder }}
          steps={isRepeatOrder ? orderCreateSteps(CONFIRM_ORDER_INDEX) : orderCreateSteps()}
          onDone={onDone}
          backButtonUrl={onBack}
        />
      </Box>
    </ContentWrapper>
  );
};

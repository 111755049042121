import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    background: palette.common.white,
    '&:hover, &:focus, &:active': {
      background: palette.common.white,
    },
  },
}));

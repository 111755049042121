import React from 'react';
import { useQuery } from '@apollo/client';

import { BarcodeModal as BarcodeModalView } from './barcode-modal';
import { IBarcodeModal } from '../barcode.models';
import { GET_BARCODE } from '../../../../../graphql/profile/queries';
import { IBarcodeData } from '../../../../../graphql/profile/models/get-barcode.models';

export const BarcodeModal = (props: IBarcodeModal): JSX.Element => {
  const { data, loading, error } = useQuery<IBarcodeData>(GET_BARCODE);

  return (
    <BarcodeModalView
      myAcuvueId={data?.profile?.myAcuvueId}
      myAcuvueCode={data?.profile?.myAcuvueToken?.code}
      isLoading={loading}
      isError={!!error}
      modalProps={props}
    />
  );
};

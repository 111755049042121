export interface ICmsBannerContainerProps {
  pwaCallToActionLink?: string;
  url: ICmsBannerUrl;
}

export interface ICmsBannerProps {
  source: string;
  link?: string;
}

export interface ICmsBannerData {
  banners: ICmsBanner[];
}

export interface ICmsBanner {
  pwaCallToActionLink?: string;
  url: string;
  tabletUrl: string;
  desktopUrl: string;
}

export interface ICmsBannerUrl {
  desktopUrl: string;
  tabletUrl: string;
  url: string;
}

export interface ICmsBannerImage {
  pwaCallToActionLink?: string;
  url: string;
}

export enum CmsBannerType {
  signUpBanner = 'sign_up_banner',
  unregisteredUser = 'unregistered_user',
  userWithoutVouchers = 'user_without_welcome_discounts_vouchers',
  userWithFirstWelcomeVoucher = 'user_with_welcome_discount_for_1st_purchase',
  userWithSecondWelcomeVoucher = 'user_with_welcome_discount_for_2nd_purchase',
}

import { useEffect, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';

import { OrderLayout } from '../order-layout';
import {
  setDeliveryStore,
  setIsRepeatOrder,
  setRepeatOrderProducts,
} from '../../../../../../graphql/order/order.cache';
import {
  getIndexedFormValues,
} from '../utils/get-form-values';
import {
  getParamsByPackage,
} from '../order-create-steps/add-lenses-step/utils/get-params-by-package';
import { IOrderRepeatProps } from './order-repeat-page.models';
import { updateRepeatQuantity } from '../utils/update-repeat-quantity';
import { IFormItemValues } from '../order-create-page.models';
import {
  getFormValues,
} from '../order-create-steps/add-lenses-step/utils/get-repeat-consumer-lenses';

export const OrderRepeatPage = ({
  onDone, lenses, brandsData, onBack,
}: IOrderRepeatProps): JSX.Element => {
  const products = useReactiveVar(setRepeatOrderProducts);
  const store = useReactiveVar(setDeliveryStore);
  const consumerLenses = useMemo(() => getParamsByPackage(lenses), [lenses]);

  const values: IFormItemValues[] = useMemo(() => {
    return getIndexedFormValues(getFormValues(consumerLenses));
  }, [consumerLenses, getIndexedFormValues]);

  const formProducts = updateRepeatQuantity(products!, values);

  useEffect(() => {
    return () => {
      setIsRepeatOrder(false);
      setRepeatOrderProducts(null);
    };
  }, []);

  return (
    <OrderLayout
      lenses={lenses}
      onDone={onDone}
      onBack={onBack}
      brandsData={brandsData}
      isRepeatOrder
      store={store!}
      products={{ products: formProducts }}
    />
  );
};

import {
  ReactNode,
  ElementType,
  ComponentPropsWithRef,
  FC,
} from 'react';

import { TLinkHref } from '../../../models/link.models';

export interface IStepperProps {
  steps: IStepProps[],
  onDone: Function;
  backButtonUrl?: TLinkHref;
  externalState?: unknown;
  disableStepIndicators?: boolean;
}

export interface IStepProps {
  title?: ReactNode;
  description?: FC<any> | ReactNode;
  descriptionStyles?: string;
  nextButtonLabel?: ReactNode;
  StepComponent: ElementType<IStepComponentProps>;
  hintText?: ReactNode;
  disableBackButon?: boolean;
  disableNextButton?: boolean;
  id: string;
  nextButtonAriaLabel?: ReactNode;
  isNextDisabled?: boolean;
  isRecaptcha?: boolean;
  recaptchaAction?: string;
  activeStepIndex?: number;
}

export interface IStepComponentProps extends ComponentPropsWithRef<ElementType> {
  stepsState: unknown;
  setStepState?: (id: string, stepState: unknown) => void;
  externalState: unknown;
  onGoNextSuccess: (stepState?: unknown) => void;
  onGoNext: () => void;
  onGoNextFail: () => void;
  onGoBack: () => void;
  onGoFirst?: () => void;
  onNextButtonDisabled: (disabledStatus: boolean) => void;
  incrementStepActionsInProgress?: () => void;
  decrementStepActionsInProgress?: () => void;
  isNavigationAllowed: boolean;
}

export interface IStepperImperativeHandleProps {
  goNext: () => void;
  goBack: () => unknown;
  goFirst?: () => unknown;
  goLast?: () => unknown;
}

export type TStepsResolver = Promise<unknown> | null;

export interface IStepperState {
  steps: IStepProps[];
  isDone: boolean;
  stepsState: unknown;
  activeStepIndex: number;
  activeStepId: string;
  stepActionsInProgress: number,
  isNavigationAllowed: boolean,
  isStepResolving: boolean,
  isNextButtonDisabled: boolean;
  stepsResolver: TStepsResolver,
}

export interface IStepperAction {
  type: IActionType;
  stepState?: unknown;
  id?: string;
  stepsResolver?: TStepsResolver;
  disabledButtonState?: boolean;
}

export enum IActionType {
  setStepState = 'setStepState',
  done = 'done',
  doneSuccess = 'doneSuccess',
  doneFail = 'doneFail',
  goBack = 'goBack',
  goNext = 'goNext',
  goNextFail = 'goNextFail',
  goNextSuccess = 'goNextSuccess',
  setNextButtonState = 'setNextButtonState',
  incrementStepActionsInProgress = 'incrementStepActionsInProgress',
  decrementStepActionsInProgress = 'decrementStepActionsInProgress',
  goFirst = 'goFirst',
  goLast = 'goLast',
  goLastSuccess = 'goLastSuccess',
}

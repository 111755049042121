import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UI_DATE_FORMAT_PLACEHOLDER } from '../../../../../constants';
import { TextField } from '../../../../common/form/text-field';
import { IChangeBirthdayFieldViewProps } from './change-birthday-field.models';

export const ChangeBirthdayField = ({
  onBirthdayChange,
}: IChangeBirthdayFieldViewProps): JSX.Element => (
  <TextField
    name="dateOfBirth"
    data-testid="change-birthday-field"
    fullWidth
    placeholder={UI_DATE_FORMAT_PLACEHOLDER}
    InputProps={{
      readOnly: true,
    }}
    label={(
      <FormattedMessage
        id="profile.dateOfBirthTextField.label"
      />
    )}
    actionProps={{
      'data-testid': 'change-date-button',
      onClick: onBirthdayChange,
      children: (
        <FormattedMessage
          id="profile.dateOfBirthTextFieldchangedateOfBirthButton.label"
        />
      ),
    }}
  />
);

